<template>
    <div>
        <!-- 掛號視窗 -->
        <vs-popup class="cd-popup-large cd-appt-popup" style="z-index: 51001" :title="title" :active.sync="active" @close="close">
            <vs-row class="cd-appt-input">
                <!-- 合作夥伴 -->
                <vs-col class="mb-base" vs-type="flex" vs-justify="center" vs-align="flex-end">
                    <span class="mr-4">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <h3>{{ selectedPartnerName }}</h3>
                    <span class="ml-4 cursor-pointer text-primary hover:font-semibold" @click="openPartnerPopup = true">{{ $t('components.apptBtn.switch') }}</span>
                </vs-col>
                <!-- 視訊 or 到院 -->
                <vs-col class="mb-base" vs-type="flex" vs-justify="center" v-if="mode != 2">
                    <vs-radio v-model="site" vs-name="site" vs-value="2" class="mr-4">{{ $t('components.apptBtn.videoChat') }}</vs-radio>
                    <vs-radio v-model="site" vs-name="site" vs-value="1" v-if="siteConfig == 1">{{ $t('components.apptBtn.onsiteVisit') }}</vs-radio>
                </vs-col>
                <!-- 選擇民眾 -->
                <vs-col class="px-4 mb-base" vs-lg="6" vs-sm="6" vs-xs="12">
                    <div class="flex justify-between items-end">
                        <span><span class="text-danger">* </span>{{ $t('components.apptBtn.patient') }}</span>
                        <span class="cd-p-small">{{ $t('components.apptBtn.patientTip') }}</span>
                    </div>
                    <el-select
                        class="w-full"
                        popper-class="cd-appt-popper"
                        v-model="patient.pid"
                        id="select"
                        ref="select"
                        filterable
                        remote
                        clearable
                        placeholder=""
                        :remote-method="searchpartnermember"
                        :loading="patient.loadingmember"
                        @clear="removesearchpartnermember"
                        @hook:mounted="cancalReadOnly"
                        @visible-change="cancalReadOnly"
                        @focus="cancalReadOnly"
                        autocomputed
                    >
                        <el-option v-for="(item, index) in patient.MemberList" :key="index" :label="item.name + ' (' + item.account + ')'" :value="item.pid" clearable filter> </el-option>
                    </el-select>
                </vs-col>
                <!-- 選擇醫師 -->
                <vs-col class="px-4 mb-base" vs-lg="6" vs-sm="6" vs-xs="12">
                    <span class="text-danger">* </span>
                    <span v-if="mode == 2">{{ $t('components.apptBtn.selectCsDoc') }}</span>
                    <span v-else>{{ $t('components.apptBtn.doctor') }}</span>
                    <el-select class="w-full" popper-class="cd-appt-popper" id="select_doc" v-model="sdid" filterable clearable placeholder="">
                        <el-option-group :label="$t('components.apptBtn.myself')" v-if="mode != 2">
                            <el-option :label="dname" :value="did" clearable filter> </el-option>
                        </el-option-group>
                        <el-option-group :label="$t('components.apptBtn.myContact')">
                            <el-option v-for="(item, index) in contactDocList" :key="index" :label="item.name" :value="item.did" clearable filter> </el-option>
                        </el-option-group>
                        <el-option-group
                            :label="
                                $t('components.apptBtn.whosDoctor', {
                                    partnerName: selectedPartnerName,
                                })
                            "
                        >
                            <el-option v-for="(item, index) in partnerDocList" :key="index" :label="item.name" :value="item.did" clearable filter> </el-option>
                        </el-option-group>
                    </el-select>
                </vs-col>
                <!-- 民眾代理人(醫師回撥對象) -->
                <vs-col class="px-4 mb-base" :class="sdid && patient.pid ? '' : 'cd-visibility'" vs-lg="6" vs-sm="6" vs-xs="12" v-if="site == 2 || (mode == 1 && site == 1) || mode == 2">
                    <div v-if="site == 2">
                        <span v-if="mode == 2">{{ $t('components.apptBtn.doctor') }}</span>
                        <span v-else>{{ $t('components.apptBtn.callBack') }}</span>
                        <el-select class="w-full" popper-class="cd-appt-popper" v-model="callbackdid" filterable placeholder="" :disabled="mode == 2" v-if="proxy">
                            <el-option v-for="(item, index) in proxy" :key="index" :label="item.name" :value="item.did" clearable filter> </el-option>
                        </el-select>
                    </div>
                </vs-col>
                <!-- 費用(一般) -->
                <vs-col class="px-4 mb-base flex" :class="sdid && patient.pid ? '' : 'cd-visibility'" vs-lg="6" vs-sm="6" vs-xs="12" v-if="mode != 1 && site == 2">
                    <span>{{ $t('components.apptBtn.fee') }}：{{ isVip ? 0 : fee }} TWD</span>
                    <span v-if="isVip"> (VIP)</span>
                </vs-col>
                <!-- 費用(特約) -->
                <vs-col class="px-4 mb-base" :class="sdid && patient.pid ? '' : 'cd-visibility'" vs-lg="6" vs-sm="6" vs-xs="12" v-if="mode == 1">
                    <span class="text-danger">* </span>
                    <span>{{ $t('components.apptBtn.fee') }}</span>
                    <el-input class="w-full" type="number" v-model="fee" clearable> </el-input>
                </vs-col>
                <!-- 諮詢問題 -->
                <vs-col class="px-4 mb-base" :class="sdid && patient.pid ? '' : 'cd-visibility'">
                    <div class="mb-2">
                        <span>{{ $t('components.apptBtn.problem') }}</span>
                    </div>
                    <div class="flex flex-wrap mb-2">
                        <vs-checkbox v-model="selectedService[index]" v-for="(item, index) in serviceData" :key="index">{{ item.word }}</vs-checkbox>
                    </div>
                    <div>
                        <el-input class="w-full" type="text" placeholder="其他問題" v-model="service" clearable> </el-input>
                    </div>
                </vs-col>
                <!-- 日期時間設定 -->
                <vs-col class="px-4" :class="sdid && patient.pid ? '' : 'cd-visibility'">
                    <span class="text-danger">* </span>
                    <span>{{ $t('components.apptBtn.dateTimeSetting') }}</span>
                </vs-col>
                <!-- 日期(一般) -->
                <vs-col class="px-4 cd-form-group" :class="sdid && patient.pid ? '' : 'cd-visibility'" vs-lg="6" vs-sm="6" vs-xs="12" vs-type="flex" vs-justify="center" v-if="mode == 0 || mode == 2">
                    <datepicker :inline="true" :disabledDates="disabledDates" v-model="date" @changedMonth="fetchShift(sdid)"></datepicker>
                </vs-col>
                <!-- 時段(一般) -->
                <vs-col class="px-4 cd-form-group" :class="sdid && patient.pid ? '' : 'cd-visibility'" vs-lg="6" vs-sm="6" vs-xs="12" v-if="mode == 0 || (mode == 2 && intervalOption)">
                    <div v-for="(item, index) in intervalOption" :key="index">
                        <div class="flex justify-between items-center">
                            <div>
                                <span>{{ interval(item.id) }} {{ item.start_time + '~' + item.end_time }}</span
                                ><br />
                                <span>{{ $t('components.apptBtn.peopleNum') + item.count + '/' + limit }}</span>
                            </div>
                            <div>
                                <vs-button class="px-4 py-3" :disabled="true" v-if="item.open == false">{{ $t('components.apptBtn.doctorLeave') }}</vs-button>
                                <div v-else-if="item.overtime == true">
                                    {{ $t('components.apptBtn.timeOut') }}
                                </div>
                                <vs-button class="px-8 py-3" :disabled="true" v-else-if="limit <= item.count">滿號</vs-button>
                                <vs-button class="px-8 py-3" v-else @click="reservation(item.id)">{{ $t('components.apptBtn.appointment') }}</vs-button>
                            </div>
                        </div>
                        <vs-divider v-if="index != 2" />
                    </div>
                </vs-col>
                <!-- 日期(特約) -->
                <vs-col class="px-4 cd-form-group" :class="sdid && patient.pid ? '' : 'cd-visibility'" vs-lg="6" vs-sm="6" vs-xs="12" vs-type="flex" vs-justify="center" v-if="mode == 1">
                    <datepicker :inline="true" :disabledDates="disabledDates" v-model="date"></datepicker>
                </vs-col>
                <!-- 時間 & 諮詢時長(特約) -->
                <vs-col class="px-4 cd-form-group" :class="sdid && patient.pid ? '' : 'cd-visibility'" vs-lg="6" vs-sm="6" vs-xs="12" v-if="mode == 1">
                    <div class="mb-base">
                        <span class="text-danger">* </span>
                        <span>{{ $t('components.apptBtn.time') }}</span>
                        <el-time-picker class="w-full" popper-class="cd-appt-popper" v-model="time" value-format="HH:mm:00"> </el-time-picker>
                        <span class="cd-p-small">{{ $t('components.apptBtn.timeTip') }}</span>
                    </div>
                    <span class="text-danger">* </span>
                    <span>{{ $t('components.apptBtn.totalTime') }}</span>
                    <el-input class="w-full mb-base" v-model="minutes" type="number" clearable> </el-input>
                    <div class="flex justify-around items-end">
                        <vs-button type="border" @click="close">{{ $t('popup.cancel') }}</vs-button>
                        <vs-button @click="reservation">{{ $t('popup.confirm') }}</vs-button>
                    </div>
                </vs-col>
            </vs-row>
        </vs-popup>
        <!-- 選擇合作夥伴視窗 -->
        <select-partner :openPopup="openPartnerPopup" @closePartnerPopup="closePartnerPopup"></select-partner>
    </div>
</template>

<script>
import { fetchPartnerSite, checkMemberFreePay, fetchDoctorListByPartner, searchpartnermember, getDoctorGeneralFee, getCountByUnreadNotify, reservation, fetchShift, getCanWords } from '@/api/user'
import Datepicker from 'vuejs-datepicker'
import selectPartner from './selectPartner.vue'
import importSetWord from '@/components/importSetWord'

export default {
    data() {
        return {
            active: false,
            title: '',
            site: 2,
            siteConfig: 0,
            patient: {
                pid: '',
                name: '',
                loadingmember: false,
                MemberList: [],
            },
            sdid: null,
            contactDocList: [],
            partnerDocList: [],
            selectedPartnerName: '',
            callbackdid: 0,
            isVip: false,
            fee: null,
            service: '',
            date: '',
            disabledDates: {
                to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), // Disable all dates up to specific date
                from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 370), // Disable all dates after specific date
                days: [],
                dates: [],
            },
            time: '',
            minutes: null,
            shiftdata: [],
            intervalOption: [],
            openPartnerPopup: false,
            shift: [],
            fixedShift: [],
            limit: 0,
            selectedService: [],
            serviceData: [],
        }
    },
    props: {
        activePopup: {
            type: Boolean,
            default: false,
            require: true,
        },
        mode: {
            type: Number,
            default: 0,
            require: false,
        },
        // pid & name
        patientData: {
            type: Object,
            default: null,
            require: false,
        },
    },
    watch: {
        sdid(n) {
            if (!n) return
            this.date = new Date()
            this.checkMemberFreePay()
            if (this.mode == 1) return
            this.disabledDates.days = []
            this.getDoctorGeneralFee()
            this.fetchShift(n)
            this.fetchService()
        },
        'patient.pid'(n) {
            if (!n) {
                this.patient.name = ''
                return
            }
            if (!this.patient.name)
                this.patient.name = _.find(this.patient.MemberList, {
                    pid: n,
                }).name
            this.checkMemberFreePay()
            if (this.mode != 1) this.getDoctorGeneralFee()
        },
        date(n, o) {
            if (!n) return
            if (this.mode == 1) return
            if (o && n.getMonth() == o.getMonth()) {
                var _self = this
                _self.intervalOption = _.get(
                    _.find(_self.shift, {
                        date: _self.customFormatter(_self.date),
                    }),
                    'interval',
                    []
                )
                _self.intervalOption.forEach(function (v) {
                    _.setWith(v, ['overtime'], new Date().getTime() > new Date(_self.$moment(_self.date).format('YYYY-MM-DD') + ' ' + v.end_time).getTime())
                })
            } else {
                this.fetchShift(this.sdid)
            }
        },
        activePopup(n) {
            this.active = n
            this.title = this.getTitle(this.mode)
            if (n == true && this.patientData) {
                this.patient = {
                    pid: '',
                    name: '',
                    loadingmember: false,
                    MemberList: [],
                }
                this.searchpartnermember(this.patientData.name)
                this.patient.pid = this.patientData.pid
                this.patient.name = this.patientData.name
            }
        },
        mode(n) {
            this.cleanField()
            if (n == 1) {
                this.disabledDates.days = []
                this.disabledDates.dates = []
            } else if (n == 2) {
                this.callbackdid = this.did
            }
        },
    },
    components: {
        Datepicker,
        selectPartner,
        importSetWord,
    },
    computed: {
        did() {
            return this.$store.getters.did
        },
        mid() {
            return this.$store.state.mid
        },
        dname() {
            return this.$store.getters.name
        },
        partnerid() {
            return this.$store.getters.partner
        },
        partnerlist() {
            return JSON.parse(localStorage.getItem('partnerlist'))
        },
        proxy() {
            var proxy_list = []
            this.$store.state.proxy.forEach(function (v) {
                proxy_list.push({ did: v.did, mid: v.mid, name: v.name })
            })
            if (this.mode != 2) this.callbackdid = 0
            _.pull(proxy_list, _.find(proxy_list, { did: this.sdid }))
            _.pull(proxy_list, _.find(proxy_list, { did: this.did }))
            if (this.sdid != this.did)
                proxy_list.unshift({
                    did: this.did,
                    mid: this.mid,
                    name: this.$t('components.apptBtn.inPerson') + '：' + this.dname,
                })
            if (this.mode != 2)
                proxy_list.unshift({
                    did: 0,
                    mid: null,
                    name: this.$t('components.apptBtn.patientInPerson') + '：' + this.patient.name,
                })
            return proxy_list
        },
    },
    mounted() {
        this.fetchDoctorListByPartner(this.partnerid)
        this.fetchPartnerSite(this.partnerid)
        this.loadProxy()
        document.getElementById('select').removeAttribute('readOnly')
    },
    methods: {
        // 關閉掛號視窗
        close() {
            this.$emit('closeApptPopup', false)
        },
        // 取合作夥伴的到院看診狀態
        fetchPartnerSite(partnerid) {
            fetchPartnerSite(partnerid).then((res) => {
                if (res.data.status == 'OK') {
                    this.siteConfig = res.data.data
                }
            })
        },
        // 搜尋民眾
        searchpartnermember(query) {
            if (query != '') {
                this.patient.loadingmember = true
                setTimeout(() => {
                    searchpartnermember({
                        partnerid: this.partnerid,
                        query: query,
                    }).then((res) => {
                        this.patient.MemberList = []
                        if (res.data.status == 'OK') {
                            this.patient.MemberList = res.data.items
                        }
                        this.patient.loadingmember = false
                    })
                }, 100)
            } else {
                this.patient.MemberList = []
            }
        },
        // 篩選符合民眾IOS Keyboard無法跳出 修復方法
        cancalReadOnly() {
            this.$nextTick(() => {
                const select = document.querySelector('#select')
                const select_doc = document.querySelector('#select_doc')
                const inputElement = this.$el.querySelector('.el-input__inner')
                const timer = setTimeout(() => {
                    select.removeAttribute('readonly')
                    select_doc.removeAttribute('readonly')
                    clearTimeout(timer)
                }, 200)
                if (inputElement) {
                    inputElement.removeAttribute('readonly')
                }
            })
        },
        // 清空民眾欄位
        removesearchpartnermember() {
            this.patient.MemberList = []
        },
        // 檢查是否為VIP會員，預設費用為0
        checkMemberFreePay() {
            if (!this.patient.pid || !this.sdid) return
            const payload = {
                sdid: this.sdid,
                spid: this.patient.pid,
                partner_id: this.partnerid,
            }
            checkMemberFreePay(payload).then((res) => {
                if (res.data.status == 'OK') {
                    this.isVip = res.data.VIP
                }
            })
        },
        // 取這個合作夥伴裡可選擇的醫師名單
        fetchDoctorListByPartner(partnerid) {
            const selectedPartner = _.find(this.partnerlist, {
                id: partnerid.toString(),
            })
            this.selectedPartnerName = selectedPartner.company
            fetchDoctorListByPartner(partnerid).then((res) => {
                if (res.data.status == 'OK') {
                    this.contactDocList = res.data.contact
                    this.partnerDocList = res.data.partner
                    this.fetchPartnerSite(partnerid)
                }
            })
        },
        // 取醫師台灣一般時段的價錢
        getDoctorGeneralFee() {
            if (!this.patient.pid || !this.sdid) return
            if (this.isVip == true) {
                this.fee = 0
                return
            }
            getDoctorGeneralFee(this.sdid).then((res) => {
                if (res.data.status == 'OK') {
                    this.fee = res.data.data
                } else {
                    console.log('Get Doctor Fee Error')
                }
            })
        },
        // 日期格式
        customFormatter(date) {
            return this.$moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
        },
        // 取醫師班表 (新版)
        fetchShift(sdid) {
            var _self = this
            let payload = {
                sdid: sdid,
                partner_id: _self.partnerid,
                year: new Date(_self.date).getFullYear(),
                month: new Date(_self.date).getMonth() + 1,
            }
            fetchShift(payload).then((res) => {
                this.shift = _.cloneDeep(res.data.shift)
                this.fixedShift = _.cloneDeep(res.data.fixedShift)
                this.limit = res.data.limit
                for (var i = 1; i <= 7; i++) {
                    if (!_.find(res.data.fixedShift, { week: i })) {
                        i == 7 ? _self.disabledDates.days.push(0) : _self.disabledDates.days.push(i)
                    }
                }
                if (res.data.holiday) {
                    res.data.holiday.forEach(function (v) {
                        _self.disabledDates.dates.push(new Date(v))
                    })
                }
                _self.intervalOption = _.get(
                    _.find(res.data.shift, {
                        date: _self.customFormatter(_self.date),
                    }),
                    'interval',
                    []
                )
                _self.intervalOption.forEach(function (v) {
                    _.setWith(v, ['overtime'], new Date().getTime() > new Date(_self.$moment(_self.date).format('YYYY-MM-DD') + ' ' + v.end_time).getTime())
                    // _.setWith(v, ["limit"], res.data.limit)
                })
            })
        },
        // 回傳 時段 文字
        interval(value) {
            const statusMap = {
                1: this.$t('components.apptBtn.am'),
                2: this.$t('components.apptBtn.pm'),
                3: this.$t('components.apptBtn.night'),
            }
            return statusMap[value]
        },
        // 掛號
        reservation(interval = 1) {
            if (!this.checkField()) return
            var date = this.$moment(this.date).format('YYYY-MM-DD')
            this.fee = this.mode != 1 && this.isVip ? 0 : this.fee
            if (this.mode == 1) {
                if (this.time.substring(0, 2) >= 9 && this.time.substring(0, 2) < 13) {
                    interval = 1
                } else if (this.time.substring(0, 2) >= 13 && this.time.substring(0, 2) < 18) {
                    interval = 2
                } else if (this.time.substring(0, 2) >= 18 && this.time.substring(0, 2) < 24) {
                    interval = 3
                } else {
                    interval = 4
                }
            }
            var inPerson = this.sdid == this.did ? this.dname : null
            var inContact = _.find(this.contactDocList, { did: this.sdid })
            var inPartner = _.find(this.partnerDocList, { did: this.sdid })
            var tempName = inPerson ? inPerson : inContact ? inContact.name : inPartner ? inPartner.name : null
            var _self = this
            this.selectedService.forEach(function (v, i) {
                if (v === true) _self.service += ', ' + _self.serviceData[i].word
            })
            const confirmText = [
                this.$t('components.apptBtn.confirmText_1'),
                this.$t('components.apptBtn.confirmText_2') + tempName,
                this.$t('components.apptBtn.confirmText_3') + this.patient.name,
                this.$t('components.apptBtn.confirmText_4') + parseInt(this.fee) + 'TWD',
                this.mode != 1 ? '' : this.$t('components.apptBtn.confirmText_5') + this.minutes + this.$t('components.apptBtn.minutes'),
                this.$t('components.apptBtn.confirmText_6') + date,
                this.mode != 1 ? this.$t('components.apptBtn.confirmText_7') + this.interval(interval) : this.$t('components.apptBtn.confirmText_8') + this.time,
                this.$t('components.apptBtn.confirmText_9'),
            ]
            const newDatas = []
            const h = this.$createElement
            for (const i in confirmText) {
                newDatas.push(h('h5', null, confirmText[i]))
            }
            this.$confirm(this.$t('popup.alert'), {
                confirmButtonText: this.$t('popup.confirm'),
                cancelButtonText: this.$t('popup.cancel'),
                type: 'warning',
                message: h('div', null, newDatas),
            }).then((_) => {
                var payload = {
                    spid: this.patient.pid,
                    sdid: this.sdid,
                    date: date,
                    interval: interval,
                    site: this.site,
                    pmemo: this.service,
                    partnerid: this.partnerid,
                    fee: parseInt(this.fee).toString(),
                    spec_note: this.mode,
                }
                if (this.mode == 1) {
                    payload.time = this.minutes
                    payload.reservation_time = this.time
                }
                if (this.callbackdid && this.site == 2) payload.proxy_call_did = this.callbackdid
                reservation(payload).then((res) => {
                    if (res.data.status == 'OK') {
                        this.notify('grey', this.$t('message.register_success'), res.data.message)
                    } else {
                        this.notify('danger', this.$t('message.register_error'), '')
                    }
                    // if (this.mode == 0 && this.sdid == this.did)
                    //     this.$store.dispatch('fetchOtherDayReservation', {
                    //         partnerid: this.partnerid,
                    //     })
                    // if (this.mode == 0 && this.sdid == this.did)
                    //     this.$store.dispatch('fetchQueue', {
                    //         currentday: this.$moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
                    //         partnerid: this.partnerid,
                    //     })
                    // if (this.mode == 0)
                    //     this.$store.dispatch('fetchAgentReservation', {
                    //         partnerid: this.partnerid,
                    //     })
                    // if (this.mode == 1)
                    //     this.$store.dispatch('fetchSpecialReservation', {
                    //         partnerid: this.partnerid,
                    //     })
                    this.$bus.$emit('createAppt')
                    // this.$store.dispatch('fetchApptUndoneQuantity', this.partnerid)
                    this.$store.dispatch('getTodayQueuesUndoneQuantity')
                    this.getCountByUnreadNotify()
                    this.cleanField()
                    this.close()
                })
            })
        },
        // 關閉選擇合作夥伴視窗視窗
        closePartnerPopup(v) {
            this.openPartnerPopup = v
        },
        // 視窗通知
        notify(color, title, text) {
            this.$vs.notify({
                color: color,
                title: title,
                text: text,
                position: 'top-center',
            })
        },
        // 清除欄位
        cleanField() {
            if (!this.patientData) {
                this.patient = {
                    pid: '',
                    name: '',
                    loadingmember: false,
                    MemberList: [],
                }
            }
            this.sdid = null
            this.service = ''
            this.date = ''
            this.callbackdid = null
            this.isVip = false
            this.fee = null
            this.time = ''
            this.minutes = null
            this.selectedService = []
        },
        // 取未讀通知數量
        getCountByUnreadNotify() {
            var _self = this
            getCountByUnreadNotify()
                .then((res) => {
                    if (res.data.status == 'OK') {
                        _self.$store.commit('SET_NOTIFY_UNFINISH_TOTAL', res.data.count)
                    }
                })
                .catch((error) => {
                    console.log('notify count err : ' + error)
                })
        },
        // 檢查欄位
        checkField() {
            if (!this.patient.pid) {
                this.notify('danger', this.$t('components.apptBtn.errorMsg1'), '')
                return false
            }
            if (!this.sdid) {
                this.notify('danger', this.$t('components.apptBtn.errorMsg2'), '')
                return false
            }
            if (!this.date) {
                this.notify('danger', this.$t('components.apptBtn.errorMsg3'), '')
                return false
            }
            if (this.mode == 1 && (!this.fee || this.fee < 0)) {
                this.notify('danger', this.$t('components.apptBtn.errorMsg4'), '')
                return false
            }
            if (this.mode == 1 && !this.time) {
                this.notify('danger', this.$t('components.apptBtn.errorMsg5'), '')
                return false
            }
            if (this.mode == 1 && (!this.minutes || this.minutes < 0)) {
                this.notify('danger', this.$t('components.apptBtn.errorMsg6'), '')
                return false
            }
            return true
        },
        // 取服務項目
        fetchService() {
            // this.$store.dispatch('fetchWords', 'service').then((res) => {
            //     this.serviceData = res.data.data
            // })
            const payload = { sdid: this.sdid, type: 'service' }
            getCanWords(payload).then((res) => {
                this.serviceData = res.data.data.data
            })
        },
        // 取標題名稱
        getTitle(index) {
            const statusMap = {
                0: this.$t('components.apptBtn.appt'),
                1: this.$t('components.apptBtn.specAppt'),
                2: this.$t('components.apptBtn.consultation'),
            }
            return statusMap[index]
        },
        loadProxy() {
            this.$store.dispatch('fetchProxy')
        },
    },
}
</script>
